/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment-mini';
import Account from '../../managers/Account';
import CreatorManager from '../../managers/Creator';
import AnnouncementsManager from '../../managers/Announcements';
import SimpleModal from '../modal/modal';
import LoyaltyReferrals from './loyaltyReferrals';
import LoyaltyShopReferrals from './loyaltyShopReferrals';
import LoyaltyRedemptions from './loyaltyRedemptions';
import PointsHistory from './pointsHistory';

function LoyaltyPointsWidget({
  openShopReferralModal, openReferModal, onModalClose, loyaltyAccountId, creatorId, points, tier, assetId, features, onPreviewCTA, isPreviewMode,
}) {
  const [announcements, setAnnouncements] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [percentage, setPercentage] = useState(null);
  const [showRedemptionModal, setShowRedemptionModal] = useState(false);
  const [showShopRereferralModal, setShopReferralModal] = useState(false);
  const [showRereferralModal, setReferralModal] = useState(false);
  const [showPointsHistoryModal, setShowPointsHistoryModal] = useState(false);
  const [membershipFeatures, setMembershipFeatures] = useState([]);
  const [redeemablePoints, setRedeemablePoints] = useState(0);
  const [tierPointsCeiling, setTierPointsCeiling] = useState(0);

  const getAnnouncements = async () => {
    const data = await AnnouncementsManager.getAnnouncementsByBrand(creatorId, Account.getToken());
    // TODO: Calculate how many are in the announcements array
    if (data && data.announcements) {
      setAnnouncements(data.announcements);
    }
  };

  const handleStickyWidget = () => {
    const stickyBrand = document.getElementById('brandWidget');
    const benefitsSection = document.getElementById('benefitsPanel');
    let stickyStop = 1400;
    console.log(benefitsSection);
    console.log(benefitsSection.clientHeight);
    if (benefitsSection) {
      stickyStop = benefitsSection.clientHeight + 200;
    }
    window.addEventListener('scroll', () => {
      if ((stickyBrand.clientHeight + 200) < window.innerHeight) {
        if (window.scrollY > 730 && window.scrollY < stickyStop) {
          stickyBrand.classList.add('-fixed');
          stickyBrand.classList.remove('-psuedo-fixed');
        } else if (window.scrollY > stickyStop) {
          stickyBrand.classList.remove('-fixed');
          stickyBrand.classList.add('-psuedo-fixed');
        } else {
          stickyBrand.classList.remove('-fixed');
          stickyBrand.classList.remove('-psuedo-fixed');
        }
      } else {
        stickyBrand.classList.remove('-fixed');
        stickyBrand.classList.remove('-psuedo-fixed');
      }
    });
  };

  const getUsersPoints = async (ceiling) => {
    let tierPercentage = 0;
    const maxPoints = ceiling || tierPointsCeiling;
    try {
      const data = await Account.getUserLoyaltyPoints(Account.getToken(), loyaltyAccountId);
      console.log(data);
      if (data) {
        const meterPoints = data.currentPoints >= data.pastPoints ? data.currentPoints : data.pastPoints;
        console.log(meterPoints);
        // setTierPoints(data.currentPoints);
        if (meterPoints >= maxPoints) {
          tierPercentage = 100;
        } else {
          tierPercentage = (meterPoints / maxPoints) * 100;
        }
        setPercentage(tierPercentage.toFixed());
      }
      if (data && data.redeemablePoints) {
        setRedeemablePoints(data.redeemablePoints);
      }
    } catch (error) {
      toast.error('Oops. There was a problem getting your points. Please try again later.');
    }
  };

  const getTiers = async () => {
    const data = await CreatorManager.getTiersByMembershipId(assetId, Account.getToken());
    const allTiers = [];
    if (data && data.tiers && data.tiers.length > 0) {
      const totalTiers = data.tiers.length;
      data.tiers.forEach((tier, i) => {
        allTiers.push({
          index: tier.sequence,
          variationName: tier.name,
          value: tier.sequence === 0 ? 5 : Math.round((tier.sequence + 1) * (100 / totalTiers)),
          minPoints: tier.minPoints,
          maxPoints: tier.maxPoints,
        });
      });
      setTiers(allTiers);
      setTierPointsCeiling(allTiers[allTiers.length - 1].minPoints);
      getUsersPoints(allTiers[allTiers.length - 1].minPoints);
    } else {
      toast.error('Oops. There was a problem getting tier information. Please try again later.');
    }
  };

  const displayRedemptionModal = (e) => {
    e.preventDefault();
    if (isPreviewMode) {
      onPreviewCTA();
      return;
    }
    setShowRedemptionModal(true);
  };

  const displayPointsHistoryModal = (e) => {
    e.preventDefault();
    if (isPreviewMode) {
      onPreviewCTA();
      return;
    }
    setShowPointsHistoryModal(true);
  };

  const displayReferralyModal = (e) => {
    e.preventDefault();
    if (isPreviewMode) {
      onPreviewCTA();
      return;
    }
    setReferralModal(true);
  };

  useEffect(() => {
    if (!isPreviewMode) {
      getTiers();
    }
  }, []);

  useEffect(() => {
    const availableFeatures = [];
    if (features && features.length > 0) {
      features.forEach((feature) => {
        availableFeatures.push(feature.featureName);
      });
      setMembershipFeatures(availableFeatures);
    }
  }, [features]);

  useEffect(() => {
    if (openReferModal) {
      setReferralModal(true);
    }
  }, [openReferModal]);

  useEffect(() => {
    if (openShopReferralModal) {
      setShopReferralModal(true);
    }
  }, [openShopReferralModal]);

  useEffect(() => {
    if (!showRereferralModal || !showShopRereferralModal) {
      onModalClose();
    }
  }, [showRereferralModal, showShopRereferralModal]);

  return (
    <div id="brandWidget" className="brand-widget -loyalty-widget">
      <SimpleModal isOpen={showPointsHistoryModal} onBeforeClose={() => setShowPointsHistoryModal(false)}>
        <PointsHistory loyaltyAccountId={loyaltyAccountId} />
      </SimpleModal>
      <SimpleModal isOpen={showRedemptionModal} onBeforeClose={() => setShowRedemptionModal(false)}>
        <LoyaltyRedemptions assetId={assetId} tier={tier} points={redeemablePoints} onUpdatePoints={() => getUsersPoints()} onCloseModal={() => setShowRedemptionModal(false)} />
      </SimpleModal>
      <SimpleModal isOpen={showRereferralModal} onBeforeClose={() => setReferralModal(false)}>
        <LoyaltyReferrals assetId={assetId} />
      </SimpleModal>
      <SimpleModal isOpen={showShopRereferralModal} onBeforeClose={() => setShopReferralModal(false)}>
        <LoyaltyShopReferrals assetId={assetId} />
      </SimpleModal>
      <div className="brand-widget__loyalty-progress">
        <div className="brand-widget__progress-bar">
          {tiers && tiers.length > 0 ? (
            <div className="brand-widget__progress-bar-overflow">
              <img className="progress-bar_start" src="https://assets.heyethos.com/classicsix/ui/meter-cap-transparent.png" alt="Loyalty meter cap" />
              {tiers.map((item, i) => (
                <div className={'progress-bar__label -slot' + (item.index + 1)} key={i}>{item.variationName}</div>
              ))}
              <img className="progress-bar_end" src="https://assets.heyethos.com/classicsix/ui/meter-cap-transparent.png" alt="Loyalty meter cap" />
              <div
                className="brand-widget__progress-bar-bar"
                style={{
                  transform: 'rotate(' + (45 + (percentage * 1.8)) + 'deg)',
                }}>
              </div>
            </div>
          ) : (
            <div className="brand-widget__progress-bar-overflow">
              <img className="progress-bar_start" src="https://assets.heyethos.com/classicsix/ui/meter-cap-transparent.png" alt="Loyalty meter cap" />
              <img className="progress-bar_end" src="https://assets.heyethos.com/classicsix/ui/meter-cap-transparent.png" alt="Loyalty meter cap" />
              <div
                className="brand-widget__progress-bar-bar"
                style={{
                  transform: 'rotate(' + (45 + (0 * 1.8)) + 'deg)',
                }}>
              </div>
            </div>
          )}
          {/* <div className="brand-widget__progress-label">324 pts</div> */}
          <div className="loyalty-points-widget__logo">
            {process.env.LOGO_INVERSE
              ? <img src={process.env.LOGO_INVERSE} alt={process.env.SITE_NAME} />
              : <img src={process.env.LARGE_LOGO} width={process.env.NAV_LOGO_WIDTH} alt={process.env.SITE_NAME} />}
          </div>
        </div>
      </div>
      <p>
        You are currently on the
        {' '}
        {tier.variationName}
        {' '}
        tier! Earn more points to unlock higher tiers and more rewards!
      </p>
      {/* <div className="brand-widget__tier-info">
        <div className="brand-widget__tier-title">{tier ? tier.variationName : 'Unknown'}</div>
      </div> */}
      {/* <div className="brand-widget__description">{process.env.SITE_DESCRIPTION}</div> */}
      <a href={process.env.CLIENT_WEBSITE} target="_blank" className="brand-widget__button" rel="noreferrer">Shop now</a>
      {announcements.length > 0
      && (
      <div className="brand-widget__updates">
        <div className="brand-widget__updates--header">
          Updates from
          {' '}
          {process.env.SITE_NAME}
        </div>
        <div className="brand-widget__updates--container">
          {announcements.map((announcement) => (
            <div
              key={announcement.announcementId}
              className="brand-widget__updates--item">
              <div dangerouslySetInnerHTML={{ __html: announcement.textContent }}></div>
              <div className="brand-widget__updates--date">
                {moment(announcement.dateCreated).format('MMM DD, YYYY')}
              </div>
            </div>
          ))}
        </div>
        
        {/* <Link to="/account/collection" className="brand-widget__button">View more</Link> */}

      </div>
      )}
      <div className="loyalty-points-widget__features">
        <div className="loyalty-points-widget__redeem">
          Redeemable Points
          <div className="loyalty-points-widget__redeem--points">
            {redeemablePoints}
          </div>
        </div>
        <p>Redeem these points towards eligible discounts for your next purchase.</p>
        <a
          className="loyalty-points-widget__link"
          href="#"
          onClick={(e) => displayPointsHistoryModal(e)}>
          View points history
        </a>

        {membershipFeatures.includes('Redemption')
        && (
        <a
          href="#"
          target="_blank"
          className="brand-widget__button"
          onClick={(e) => displayRedemptionModal(e)}>
          Redeem Points
        </a>
        )}
        {membershipFeatures.includes('Referrals') && (
        <>
          <hr className="loyalty-points-widget__hr" />
          <div className="loyalty-points-widget__referral">
            <div className="loyalty-points-widget__referral--thumb">
              <img src={process.env.REFER_FRIENDS_BACKGROUND || process.env.ALTERNATE_BACKGROUND} alt="Referral thumb" />
            </div>
            <div className="loyalty-points-widget__referral--title">Refer your friends!</div>
            <div className="loyalty-points-widget__referral--description">
              For every friend you invite and signs up, you will both receive 25 points towards your account!
            </div>
            <a
            href="#"
            className="brand-widget__button"
            onClick={(e) => displayReferralyModal(e)}>
              Add Friends
            </a>
          </div>
        </>
        )}
      </div>
    </div>
  );
}

export default LoyaltyPointsWidget;
